'use strict';

angular.module('kljDigitalLibraryApp')
    .service('AutoSuggestService', ["$q", "common", "appConfig", function($q, common, appConfig) {

        this.autoSuggest = function(body) {

            var url = '/api/cases/autoSuggest/';

            return common.callApi('POST',
                url,
                appConfig.EMPTY_STRING,
                appConfig.CONTENT_TYPE,
                body
            )

            .then(function(cases) {
                return cases.data
            })
            .catch(function(error) {
                console.log(error,"====")
                return error
            })
        }

        this.journalsAutoSuggest = function(body) {

            var url = '/api/case_journals/autoSuggest/';

            return common.callApi('POST',
                url,
                appConfig.EMPTY_STRING,
                appConfig.CONTENT_TYPE,
                body
            )

            .then(function(cases) {
                return cases.data
            })

        }

        this.statueAutoSuggest = function(body) {
            var url = '/api/case_journals/autoSuggest/';

            return common.callApi('POST',
                url,
                appConfig.EMPTY_STRING,
                appConfig.CONTENT_TYPE,
                body
            )

            .then(function(cases) {
                return cases.data
            })
        }
    }])